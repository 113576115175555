.region-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.region-options {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.region-option {
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.region-option:first-child {
  background-color: #333333;
  color: #ffffff;
}
.region-option:hover {
  background-color: #333333;
  color: #ffffff;
}

.region-option.selected {
  background-color: #008507;
  color: #ffffff;
}

.region-actions {
  margin-top: 20px;
}

.btn-cta {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  background-color: #333333;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.btn-cta:hover {
  background-color: #008507;
  color: #ffffff;
}


.btn-cta-orange {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  background-color: #333333;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.btn-cta-orange:hover {
  background-color: #008507;
  color: #ffffff;
}
/* Media Queries */

/* Pour les écrans plus petits que 768px */
@media (max-width: 768px) {
  .region-options {
    flex-wrap: wrap;
  }

  .region-option {
    margin: 10px;
  }
}

/* Pour les écrans plus petits que 480px */
@media (max-width: 480px) {
  .region-selector {
    padding: 0 10px;
  }

  .region-option {
    padding: 8px 16px;
    font-size: 16px;
  }

  .btn-cta {
    padding: 8px 16px;
    font-size: 16px;
  text-align: center;

  }
}
